export const environment = {
  version: '55d8ad1bdb911245965018a2e741fa267a7ad451',
  production: true,
  apiUrl: '/api',
  arcGisServicesBasePath: '/ags/rest/services',
  geometryService: 'https://tasks.arcgisonline.com/ArcGIS/rest/services/Geometry/GeometryServer',
  swissTopoBasemapId: '55dacb9df78848b3b69cf326016f3652',
  swissTopoImageLayer: 'ch.swisstopo.swissimage',
  openDataBaseUrl: 'https://geo.fr.ch/ags/rest/services/OpenData',
  parcelsNumbersPath: '/Numeros_d_immeubles_en_vigueur/MapServer/0',
  parcelsBordersPath: '/Immeubles_en_vigueur/MapServer/0',
  adressesPath: '/Adresses_MO_RegBL_RegBAFR/MapServer/0',
  limitesPath: '/Limites_de_communes/MapServer/0',
  bugsnag: {
    apiKey: '9168f2ab09f3363f575692ed58267db6',
  },
  support : {
    feedbackLink: 'https://ecab.atlassian.net/servicedesk/customer/portal/7/group/21/create/65',
    helpLink: 'https://ecab.atlassian.net/wiki/external/MjA1MTAwNmUyOTFhNDYyZWE3MWRhNmIzZjE2YmQ4MjM',
    supportLink: 'https://ecab.atlassian.net/servicedesk/customer/portal/7/group/21/create/70',
  },
  defectImageCompressionQuality: 0.8,
  defectImageMaxSize: 1200,
  supportEmail: 'sibat@ecab.atlassian.net',
  markerIcon : 'https://static.arcgis.com/images/Symbols/Basic1/Black_1_Tear_Pin2.png'
};
